// 产品详情
<template>
  <div class="product-details-wrapper">
    <Bigimg
      title="人工智能"
      :imgUrl="require('@/assets/images/rgzn.jpeg')"
    ></Bigimg>
    <div class="product-details wrap-v1"></div>
  </div>
</template>
<script>
import Bigimg from "@/components/bigimg.vue";
import imgLeftorright from "@/components/img-leftorright";
export default {
  components: { imgLeftorright, Bigimg },
  data() {
    return {
      title: "物联网设备运营平台",
      desc:
        "物联网是物物相通，而智能物联则是指除了物物相通外，智能物联不仅仅是网络、应用与管理，而且是面向业务的智能化的应用与服务。",
      imgUrl1: require("../../assets/images/cpzs_1.png"),
      imgUrl2: require("../../assets/images/cpzs_2.jpg"),
      imgUrl3: require("../../assets/images/cpzs_3.jpg"),
      imgUrl4: require("../../assets/images/cpzs_4.jpg")
    };
  },
  methods: {
    goDetail() {
      alert("资料不完善，试试其他的吧");
    }
  }
};
</script>
<style scoped lang="scss">
.product-details {
  padding: 40px 20px 115px;
  .product-details-list {
    .product-details-item {
      display: flex;
      align-items: center;
      margin-top: 60px;
      img {
        flex: 1;
        display: block;
        width: 700px;
        /* width: 50%; */
        /* min-width: 500px; */
      }
      .product-details-detail {
        flex: 1;
        text-align: left;
        .product-details-title {
          font-size: 45px;
          font-weight: 600;
          color: hsla(0, 0%, 20%, 1);
          line-height: 63px;
        }
        .product-details-desc {
          margin-top: 45px;
          text-align: left;
          font-size: 20px;
          color: hsla(0, 0%, 20%, 1);
          line-height: 40px;
        }
      }
    }
  }
}
</style>
